<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:45:45
-->
<template>
  <div id="classification">
    <!-- 搜索 -->
    <div class="search">
      <van-button
        round
        size="small"
        block
        :icon="sousuo"
        type="default"
        to="/searchbox"
      >
        请输入关键词进行搜索
      </van-button>
    </div>
    <!-- 内容 -->
    <div class="main">
      <div class="left">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item
            v-for="item in Sidebardata"
            :key="item.id"
            :title="item.name"
            @click="storemorelevel(item.id)"
          />
        </van-sidebar>
      </div>
      <div class="right">
        <commodity-cart :categoryList="categoryList" />
      </div>
    </div>
    <van-loading
     size="24px"
     v-show="storefication"
     vertical
    >
      加载中...
    </van-loading>
  </div>
</template>
<script>
import CommodityCart from "./components/commodityCart.vue";
import { queryTreeNode } from "./service";
import sousuo from "../../../assets/icon/souuso_icon@2x.png";

export default {
  data() {
    return {
      sousuo,
      activeKey: 0,
      treeNode: null,
      storefication: false,
      Sidebardata: [],
      categoryList: [],
    };
  },
  methods: {
    async getTreeNode() {
      this.storefication = true;
      await queryTreeNode(localStorage.getItem("storeCodeclass")).then(
        (response) => {
          this.storefication = false;
          this.Sidebardata = response.data;
          this.defaultId = response?.data[0]?.id;
          this.storemorelevel(this.defaultId);
        }
      );
    },
    storemorelevel(defaultId) {
      let seconedstore = this.Sidebardata.filter(
        (item) => item.id === defaultId
      );
      this.categoryList =
        (seconedstore[0].children &&
          seconedstore[0].children.map((i) => ({
            ...i,
          }))) ||
        [];
    },
    onfocus() {
      this.$router.push("/searchbox");
    },
  },
  components: { CommodityCart },
  mounted() {
    this.getTreeNode();
  },
};
</script>
<style lang="less" scoped>
#classification {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/  .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    padding: 5px 12px;
    background-image: @GradientColor;
    .van-button__text {
      color: #96979b;
    }
    .van-button--small {
      height: 32px;
    }
  }
  .main {
    flex: 1 1 0;
    height: 100%;
    overflow: auto;
    display: flex;
    .left {
      background: #ffffff;
      width: 104px;
      height: 100%;
      border-right: 1px solid #ebedf0;
      /deep/ .van-sidebar {
        width: 100%;
        height: 100%;
        .van-sidebar-item {
          background: #ffffff;
          padding: 12px;
          .van-sidebar-item__text {
            width: 100%;
            height: 20px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
        }
        .van-sidebar-item--select::before {
          background-color: @Prompt;
          top: 44%;
        }
        .van-sidebar-item--select {
          color: #0095da;
        }
      }
    }
    .right {
      flex: 1 0 auto;
      padding: 0 8px;
      height: 100%;
      background: #f5f6f7;
      flex: 1 1 0;
      height: 100%;
      overflow: auto;
    }
  }

  /deep/ .van-icon__image {
    border-radius: 4px;
  }

  /deep/ .van-button--default {
    border: none;
  }
}
</style>
